import {NgModule, Optional, SkipSelf} from '@angular/core';
import { CommonModule } from '@angular/common';
import {SpotifyService} from './spotify.service';
import { AngularFireModule } from '@angular/fire';
import {environment} from '../../environments/environment';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireFunctionsModule, ORIGIN} from '@angular/fire/functions';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireFunctionsModule

  ],
  providers: [
    SpotifyService,
  //  { provide: ORIGIN, useValue: 'https://scottydev.me/' }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule){
      throw new Error('Core Module is already loaded. Import in App Module only');
    }
  }
}
