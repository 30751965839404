import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AngularFireFunctions} from '@angular/fire/functions';


@Injectable({
  providedIn: 'root'
})
export class SpotifyService {

  constructor(private http: HttpClient, private fns: AngularFireFunctions ) { }

  spotifyRedirect(): Observable<any> {
  const callable =  this.fns.httpsCallable('redirect');
  return callable({data: 'data'});
  }
}
