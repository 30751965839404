import {Component, OnInit} from '@angular/core';
import {SpotifyService} from './core/spotify.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'FrontEnd';
  data$: Observable<any>;
  constructor(private spotifyService: SpotifyService) {}
  ngOnInit(): void {
    this.spotifyService.spotifyRedirect().subscribe( data => {
      console.log(data);
    });
  }
}
